import {useState} from 'react';
import {ethers, BigNumber} from 'ethers';
import {Box, Button, Flex, Image, Input, Text} from '@chakra-ui/react';
import simplesABI from '../Simples.json';
import simplysPassABI from '../SimplysPass.json';
import SimplysLogo from '../assets/images/Alien.gif';

const simplesAddress = "0xD6c963A0B677501b23529F9e908556fc6305341C";
const simplysPassAddress = "0x3716444FB61cE6c6318f81f0D6e9a2980aFB37dd";
var ApprovedBalance

const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

const simples = {
    address: simplesAddress,
    abi: simplesABI.abi,
}

const mintPass = {
    address: simplysPassAddress,
    abi: simplysPassABI.abi,
}



async function main() {
    //await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let userAddress = await signer.getAddress();
  
    const simplesContract = new ethers.Contract(simples.address, simples.abi, signer);
  
    let pendingBalance = await simplesContract.allowance(userAddress, simplysPassAddress);
    let approvedBalance = ethers.utils.formatUnits(pendingBalance, 0); 
    ApprovedBalance = Math.trunc( approvedBalance / 1000000000000000000 );
  }
  main();


const Mint = ({accounts, setAccounts}) => {
    const[mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);

    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                mintPass.address,
                mintPass.abi,
                signer
            );
            try {
                const response = await contract.mint(BigNumber.from(mintAmount));
                console.log('response: ', response);
            } catch(err) {
                console.log("error: ", err)
            }
        }
    };

    
       
    
    async function handleApproval() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                simplesAddress,
                simplesABI.abi,
                signer
            );
            try {
                const response = await contract.approve(simplysPassAddress, ethers.utils.parseEther((1400 * mintAmount).toString()));
                console.log('response: ', response);
            } catch(err) {
                console.log("error: ", err)
            }
        }
    };

    

    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncrement = () => {
        if (mintAmount >= 10) return;
        setMintAmount(mintAmount + 1);
    };

    return (
        <Flex justify="center" align="center" height="100vh" paddingBottom="150px">
            <Box width="520px">
                <div>
                    <Image src={SimplysLogo} width="480px" height="480px" margin="0px -80px" />
                    <Text
                        fontSize="30px"
                        letterSpacing="-5.5%"
                        fontFamily="VT323"
                        textShadow="0 2px 2px #000000"  
                    >
                        To mint your pass with $Simples select the amount you would like to mint and click "Approve".
                        After the approval transaction is complete, click "Mint". Each mint pass costs 1400 $Simples.
                    </Text>
                </div>
            {isConnected ? (
                <div>
                    <Text>
                        Approved Balance: {ApprovedBalance}
                    </Text>
                    <Flex align="center" justify="center">
                        <Button
                            backgroundColor="#3b3b38"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            marginTop="10px"

                            onClick={handleDecrement}
                        >
                            -
                        </Button>
                        <Input 
                            readOnly
                            fontFamily="inherit"
                            width="100px"
                            height="40px"
                            textAlign="center"
                            paddingLeft="19px"
                            marginTop="10px"
                            type="number" 
                            value={mintAmount} 
                        />
                        <Button
                            backgroundColor="#3b3b38"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            marginTop="10px"
                            onClick={handleIncrement}
                        >
                            +
                        </Button>
                    </Flex>
                    <Button
                        backgroundColor="#f9d44e"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        marginTop="10px"                
                        onClick={handleApproval}
                    >
                        Approve
                    </Button>
                    <Button
                        backgroundColor="#48bb78"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        marginTop="10px"                
                        onClick={handleMint}
                    >
                        Mint
                    </Button>
                </div>
            ) : (
                <p>You must be connected to mint</p>
            )}
            </Box>
        </Flex>
    )
}

export default Mint;